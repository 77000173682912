import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["companyPostalCode", "companyCountry"];

  connect() {
    if (this.hasCompanyCountryTarget) {
      this.updatePostalCodePattern();
      $(this.companyCountryTarget).on("select2:select", () => this.updatePostalCodePattern());
    }
  }

  updatePostalCodePattern() {
    const selectedCountry = this.companyCountryTarget.value;
    let pattern;

    if (selectedCountry === "DE") {
      pattern = "^0?[0-9]{5}$";
    } else {
      pattern = "^(?=.*[A-Za-z0-9])[\\w\\s\\-]{4,8}$";
    }

    document.querySelector('.js-company-postal-code').setAttribute("pattern", pattern);
  }
}
